<template>
  <div class="body">
    <div class="plate_title">
      <div class="plate_title_name">视频分类</div>
    </div>
    <div class="bb p30">
      <div class="head flex fw_w">
        <div>
          <div>分类标题</div>
          <div class="w300">
            <el-input
              clearable
              size="large"
              v-model="name"
              placeholder="请输入标题"
            ></el-input>
          </div>
        </div>
        <div>
          <el-button @click="search" type="primary">搜 索</el-button>
        </div>
      </div>
      <div class="mb20">
        <el-button type="primary" @click="handleFormShow()">添加分类</el-button>
      </div>
      <!-- 数据列表 -->
      <div v-loading="listLoading">
        <el-table :data="categoryList" height="70vh" style="width: 100%">
          <el-table-column label="ID" width="120" prop="id"> </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="分类"
            width="200"
            prop="name"
          >
          </el-table-column>
          <el-table-column label="排序(越大越靠前)" width="160" prop="weigh">
          </el-table-column>
          <el-table-column label="操作" width="300" align="center">
            <template #default="scope">
              <el-button
                type="primary"
                size="default"
                @click="handleFormShow(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                size="default"
                @click="handleDelete(scope.$index, scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 弹出表单 -->
    <el-dialog width="500px" v-model="formShow" :title="formTitle">
      <div class="flex">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="分类名称">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="排序" v-if="form.id">
            <el-input v-model="form.weigh"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "@/api/liveVideo";
export default {
  data() {
    return {
      listLoading: false,
      list: [],
      name: "", // 分类名称检索条件
      formShow: false,
      formTitle: "",
      form: {
        id: null,
        name: "", // 分类名称
        weigh: "",
      }, // 提交表单信息
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.getLiveVideoCategoryList();
  },
  methods: {
    onmessage(e) {},
    search() {
      this.list = [];
      this.getLiveVideoCategoryList();
    },
    // 获取分类列表
    async getLiveVideoCategoryList() {
      this.listLoading = true;
      const res = await Service.getLiveVideoCategoryList({
        name: this.name,
      });
      this.categoryList = res.data;
      this.listLoading = false;
    },
    async onSubmit() {
      var form = this.form;
      var obj = { ...form };
      if (!obj.name) {
        this.$message.error("请填写分类名称");
        return false;
      }

      var res = null;
      if (obj.id) {
        res = await Service.editLiveVideoCategory(obj);
      } else {
        res = await Service.addLiveVideoCategory(obj);
      }
      this.$message.success(res.msg);
      this.formShow = false;
      this.getLiveVideoCategoryList();
    },
    handleFormShow(e) {
      this.form = {
        name: "", // 分类名称
      }; // 提交表单信息
      if (e != undefined) {
        // 修改
        this.formTitle = "修改";
        this.form.id = e.id;
        this.form.name = e.name;
        this.form.weigh = e.weigh;
      } else {
        // 添加
        this.formTitle = "添加";
      }
      this.formShow = true;
    },
    handleDelete(index, row) {
      this.$confirm("确认删除该数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.handleDeleteApi(index, row);
      });
    },
    async handleDeleteApi(index, row) {
      const res = await Service.delLiveVideoCategory({
        id: row.id,
      });
      this.$message.success(res.msg);
      this.getLiveVideoCategoryList();
    },
  },
};
</script>

<style scoped>
.body {
  height: 100vh;
}

.plate_title {
  background-color: #f8fcff;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  padding: 18px 0;
  border-top: 1px solid #ebeced;
  border-bottom: 1px solid #ebeced;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plate_title_name {
  position: relative;
  line-height: 16px;
  padding-left: 30px;
}

.plate_title_name::before {
  content: "";
  position: absolute;
  left: 13px;
  width: 4px;
  height: 16px;
  background-color: #5389f5;
  border-radius: 5px;
}

.head > div {
  margin: 0 30px 20px 0;
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.el-form {
  width: 90%;
}

.icon_del {
  display: block;
  width: 26px;
  height: 26px;
  margin-left: 10px;
}
</style>

<template>
  <div class="body flex at_c">
    <div class="w800px0">
      <div class="plate_title">
        <div class="plate_title_name">宣传图生成</div>
      </div>
      <div class="bb p20">
        <el-form
          ref="form"
          :model="form"
          label-position="top"
          label-width="80px"
        >
          <div class="flex at_c jc_b">
            <el-form-item class="w45" label="研究员：" size="large">
              <el-input v-model="form.teacher"></el-input>
            </el-form-item>
            <el-form-item class="w45" label="标题：" size="large">
              <!-- <el-input v-model="form.title"></el-input> -->
              <el-select
                v-model="form.title"
                class="m-2"
                placeholder="请选择标题类型"
                size="large"
                clearable
                filterable
                @change="changeTitle"
              >
                <el-option
                  v-for="item in titleList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
          </div>
          <div class="flex at_c jc_b">
            <el-form-item class="w45" label="日期：" size="large">
              <el-date-picker
                v-model="form.date_time"
                type="datetime"
                placeholder="选择日期时间"
                value-format="YYYY-MM-DD hh:mm:ss"
              />
            </el-form-item>
            <el-form-item class="w45" label="类型：" size="large">
              <el-radio-group v-model="form.type">
                <el-radio-button label="盘中策略" />
                <el-radio-button label="盘前策略" />
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="flex at_c jc_b">
            <el-form-item class="w45" label="话题：" size="large">
              <el-select
                v-model="topic_id"
                class="m-2"
                placeholder="请选择话题"
                size="large"
              >
                <el-option
                  v-for="item in topicList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item class="w45" label="品种：" size="large">
              <el-select
                v-model="categoryName"
                class="m-2"
                placeholder="请选择品种"
                size="large"
                clearable
                filterable
                @change="changeCategoryName"
              >
                <el-option
                  v-for="item in categoryList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="内容：">
            <div id="editor"></div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="doCut">立即生成</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="w800px">
      <div class="plate_title">
        <div class="plate_title_name">宣传图生成</div>
      </div>
      <div class="publicity_map" id="capture">
        <div class="title">
          {{ form.type == "盘中策略" ? "【盘中】" : "【盘前】" }}
          {{ categoryName }}{{ form.title }}
        </div>
        <div class="sub flex at_c">
          <!-- <div class="mr40">{{ form.teacher }}讲师：</div> -->
          <div>风暴时间：{{ form.date_time }}</div>
        </div>
        <div class="content" v-html="form.content"></div>
        <div class="info">
          <div>“数据风暴”并不构成具体的操作建议，据此操作风险自担。</div>
          <div>
            投资者依据上述内容进行投资如有亏损，损失自担，本司不承担任何责任。
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogVisible" title="宣传图" width="840px">
      <img class="w100" :src="imgUrl" alt="" />
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submit">发送</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "../api/articleTemplate.js";
import * as ServiceC from "../api/circleManage.js";
import E from "wangeditor";
import xss from "xss";
import html2canvas from "html2canvas";
export default {
  components: {
    html2canvas,
  },
  data() {
    return {
      form: {
        teacher: "投研中心",
        title: "数据风暴",
        date_time: "",
        type: "盘中策略",
        content: "",
      },
      editor: null,
      dialogVisible: false,
      imgUrl: "",
      topicList: [], // 话题列表
      topic_id: null,

      categoryList: [],
      categoryName: "",

      titleList: [
        {
          name: "数据风暴",
        },
        {
          name: "数据风暴(SVIP)",
        },
      ],
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.showRealTime();
    this.createEditor();
    this.getTopicList();
    this.getBaseInfo();
  },
  created() {},
  computed: {},
  methods: {
    onmessage(e) {},
    async getBaseInfo() {
      const res = await Service.BaseInfo();
      console.log("res", res);
      this.categoryList = res.data.categoryList;
    },
    changeTitle(e) {
      console.warn("e", e);
      this.editor.txt.html(
        `<p><font size='6'><br/></font></p><p><font size='6'>${this.categoryName}${e}：出现异动！</font></p>`
      );
    },
    changeCategoryName(e) {
      console.warn("e", e);
      this.editor.txt.html(
        `<p><font size='6'><br/></font></p><p><font size='6'>${e}${this.form.title}：出现异动！</font></p>`
      );
    },
    // 获取话题列表
    async getTopicList() {
      const res = await ServiceC.topicList({
        is_paginate: 0,
      });
      this.topicList = res.data;
    },
    async submit() {
      const res = await Service.uploadBase64({
        pic: this.imgUrl,
      });
      this.sendAppImage(res.data.url);
    },
    async sendAppImage(path) {
      const res = await Service.sendAppImage({
        agent_id: 1000026,
        path,
        type: 2,
        topic_id: this.topic_id,
      });
      this.$message.success(res.msg);
      this.dialogVisible = false;
      window.location.reload();
    },
    doCut() {
      let that = this;
      html2canvas(document.querySelector("#capture"), {
        scale: 2,
      }).then((canvas) => {
        var imgUrl = canvas.toDataURL("image/png");
        that.imgUrl = imgUrl;
        console.log("imgUrl", imgUrl);
        that.dialogVisible = true;
      });
    },
    createEditor() {
      let that = this;
      var height = 400;
      const editor = new E("#editor");
      // 可使用 base64 格式保存图片
      editor.config.uploadImgShowBase64 = true;
      editor.config.height = height;
      editor.config.zIndex = 500;
      // 取消自动 focus
      editor.config.focus = false;
      // 配置 onchange 回调函数
      editor.config.onchange = function (newHtml) {
        // var reult = newHtml.charAt(newHtml.length-1)
        console.log("newHtml", newHtml);
        that.form.content = newHtml;
      };
      // 配置触发 onchange 的时间频率，默认为 200ms
      editor.config.onchangeTimeout = 500; // 修改为 500ms
      editor.create();
      editor.txt.html(
        "<p><font size='6'><br/></font></p><p><font size='6'>数据风暴：出现异动！</font></p>"
      );

      this.editor = editor;
    },
    save() {
      var editor = this.editor;
      const html = editor.txt.html();
      const safeHtml = xss(html);
      console.log("处理过 xss 攻击的 html", safeHtml);
    },
    showRealTime() {
      var d = new Date();
      var year = d.getFullYear();
      var month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      var date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      var days = new Array("日", "一", "二", "三", "四", "五", "六");
      var day = d.getDay();
      var hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      var min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      var sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
      var cori_date = year + "-" + month + "-" + date;
      var cori_time = hour + ":" + min + ":" + sec;
      this.form.date_time = cori_date + " " + cori_time;
      // this.currentTime = cori_time;
      // this.currentWeek = "星期" + days[day];
    },
  },
};
</script>

<style scoped>
.body {
  height: 100vh;
}

.w800px0 {
  width: calc(100% - 800px);
  height: 100%;
  border-right: 1px solid #ebeced;
}

.w800px {
  width: 800px;
  height: 100%;
}

.plate_title {
  background-color: #f8fcff;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  padding: 18px 0;
  border-top: 1px solid #ebeced;
  border-bottom: 1px solid #ebeced;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plate_title_name {
  position: relative;
  line-height: 16px;
  padding-left: 30px;
}

.plate_title_name::before {
  content: "";
  position: absolute;
  left: 13px;
  width: 4px;
  height: 16px;
  background-color: #5389f5;
  border-radius: 5px;
}

.overflow_y {
  overflow-y: auto;
}

.overflow_y::-webkit-scrollbar {
  display: block;
  width: 4px;
}

.overflow_y::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px #aaa;
  background: rgba(0, 0, 0, 0.2);
}

.overflow_y::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #aaa;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.publicity_map {
  box-sizing: border-box;
  padding: 0 50px;
}

.publicity_map .title {
  font-size: 55px;
  font-weight: bold;
  box-sizing: border-box;
  padding: 20px 0 5px;
}

.publicity_map .sub {
  font-size: 22px;
  color: #6277a2;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 3px solid #dbb785;
}

.mr40 {
  margin-right: 40px;
}

.publicity_map .info {
  margin-top: 35px;
  text-align: center;
  padding-bottom: 20px;
}

.publicity_map .content {
  margin-top: 35px;
}
</style>

<template>
  <div>
    <div class="pr">
      <input
        class="upload-input"
        type="file"
        @change="handleFileChange"
        accept="video/*"
      />
      <el-button type="primary">
        <el-icon><UploadFilled /></el-icon>上传视频
      </el-button>
    </div>

    <div v-if="videoSrc || progress" class="video_body">
      <video id="video" controls :src="videoSrc"></video>
      <div v-if="uploading" class="video_progress">
        <el-progress type="circle" :percentage="progress" />
      </div>
    </div>
  </div>
</template>

<script>
import OSS from "ali-oss";

export default {
  props: {
    folderName: {
      type: String,
      default: "test",
    },
    videoUrl: {
      type: String,
      default: "",
    },
  },
  watch: {
    videoUrl(val) {
      this.videoSrc = val;
    },
  },
  data() {
    return {
      client: new OSS({
        region: "oss-cn-shanghai",
        accessKeyId: "LTAI5tRozUZ3WynSFfTzC72S",
        accessKeySecret: "HGfPDLd6zylQVVU7c9tkQT7uGKpRd7",
        bucket: "i-vce",
      }),
      uploading: false,
      // uploading: true,
      progress: 0,
      // progress: 50,
      hostName: "https://cloud.i-vce.com/",
      videoSrc: "",
      // videoSrc: "https://cloud.i-vce.com/push/liveCut/20241226/1735173705356.mp4",
    };
  },
  methods: {
    getFileName(file) {
      var d = new Date();
      var year = d.getFullYear();
      var month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      var date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      var type = file.type.substr(
        file.type.lastIndexOf("/") + 1,
        file.type.length
      );
      return (
        "push/" +
        this.folderName +
        "/" +
        year +
        month +
        date +
        "/" +
        Math.round(new Date()) +
        "." +
        type
      );
    },
    async handleFileChange(event) {
      const file = event.target.files[0];
      if (!file) return;

      this.uploading = true;
      this.progress = 0;

      try {
        const result = await this.client.multipartUpload(
          this.getFileName(file),
          file,
          {
            progress: (p, cpt) => {
              console.log("Progress:", p);
              this.progress = Math.round(p * 100);
            },
          }
        );
        console.log("上传成功:", result);
        this.$emit("uploadSuccess", this.hostName + result.name);
        this.videoSrc = this.hostName + result.name;
      } catch (err) {
        console.error("上传失败:", err);
      } finally {
        this.uploading = false;
        console.log("finally");
      }
    },
  },
};
</script>

<style scoped>
.upload-container {
  position: relative;
  overflow: hidden;
}

.upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.video_body {
  position: relative;
  width: 300px;
  margin: 10px 0;
}

video {
  width: 100%;
  display: block;
}

.video_progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.video_progress >>> span {
  color: #fff;
}
</style>

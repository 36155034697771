<template>
  <div class="body">
    <div class="plate_title">
      <div class="plate_title_name">视频管理</div>
    </div>
    <div class="bb p30">
      <div class="head flex fw_w">
        <!-- <div>
          <div>标题</div>
          <div class="w300">
            <el-input
              clearable
              size="large"
              v-model="title"
              placeholder="请输入标题"
            ></el-input>
          </div>
        </div> -->
        <div>
          <div>视频分类</div>
          <div>
            <el-select
              v-model="category_id"
              class="m-2"
              placeholder=""
              size="large"
              filterable
            >
              <el-option label="全部" :value="0" />
              <el-option
                v-for="(item, index) in categoryList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
        </div>
        <div>
          <div>发布时间</div>
          <div>
            <el-date-picker
              format="YYYY-MM-DD"
              value-format="x"
              size="large"
              v-model="time_range"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div>
          <el-button @click="search" type="primary">搜 索</el-button>
        </div>
      </div>
      <div class="mb20">
        <el-button type="primary" @click="handleFormShow()">添加视频</el-button>
      </div>
      <!-- 数据列表 -->
      <div v-loading="listLoading">
        <div class="mb20">
          <el-table :data="list" height="70vh" style="width: 100%">
            <el-table-column label="ID" width="120" prop="id">
            </el-table-column>
            <el-table-column label="封面图" width="220">
              <template #default="scope">
                <!-- <img
                  :src="scope.row.image"
                  style="width: 100px; display: block"
                  alt=""
                /> -->
                <el-image
                  style="width: 100px; display: block"
                  :src="scope.row.image"
                  :preview-src-list="[scope.row.image]"
                  :preview-teleported="true"
                  fit="contain"
                />
              </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              label="标题"
              width="300"
              prop="title"
            >
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              label="分类"
              width="200"
              prop="category.name"
            >
            </el-table-column>
            <el-table-column label="推荐" width="200">
              <template #default="scope">
                <el-tag
                  v-if="scope.row.is_recommend == 1"
                  type="success"
                  effect="dark"
                >
                  是
                </el-tag>
                <el-tag v-else type="danger" effect="dark"> 否 </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              label="点赞数"
              width="200"
              prop="accept_num"
            >
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              label="评论数"
              width="200"
              prop="comment_num"
            >
            </el-table-column>
            <el-table-column
              label="创建时间"
              width="180"
              prop="createtime_text"
            >
            </el-table-column>
            <el-table-column
              label="更新时间"
              width="180"
              prop="updatetime_text"
            >
            </el-table-column>
            <el-table-column
              label="操作"
              width="200"
              align="center"
              fixed="right"
            >
              <template #default="scope">
                <el-button
                  type="primary"
                  size="default"
                  @click="handleFormShow(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  size="default"
                  @click="handleDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出表单 -->
    <el-dialog
      width="900px"
      v-model="formShow"
      :title="formTitle"
      :before-close="handleClose"
    >
      <div class="flex" v-loading="formLoading">
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item label="标题">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="副标题">
            <el-input v-model="form.sub_title"></el-input>
          </el-form-item>
          <el-form-item label="分类">
            <el-select
              v-model="form.category_id"
              class="m-2"
              placeholder="请选择分类"
              filterable
            >
              <el-option
                v-for="(item, index) in categoryList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="是否推荐">
            <el-switch
              v-model="form.is_recommend"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              inline-prompt
              active-text="是"
              inactive-text="否"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="封面图">
            <coverImgTemplate
              :title="form.title"
              :sub_title="form.sub_title"
            ></coverImgTemplate>
          </el-form-item>
          <el-form-item label="视频">
            <div>
              <uploadOSS
                folderName="liveCut"
                @uploadSuccess="uploadSuccess"
                :videoUrl="form.video"
              ></uploadOSS>

              <!-- <el-button
                v-if="form.video"
                type="success"
                @click="getCurrentVideoImg"
              >
                点击选择当前画面作为封面
              </el-button> -->
            </div>
          </el-form-item>
          <!-- <template v-if="form.video">
            <el-form-item label="封面图比例">
              <el-radio-group v-model="form.image_type" @change="changeImg">
                <el-radio
                  v-for="(item, index) in rateRadioList"
                  :key="index"
                  :label="item.label"
                ></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="选取封面">
              <img :src="form.image" width="300" alt="" />
            </el-form-item>
          </template> -->
          <el-form-item>
            <el-button class="mt30" type="primary" @click="onSubmit"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "@/api/liveVideo";
import { Delete, Plus } from "@element-plus/icons-vue";
const OSS = require("ali-oss");
import { formatTime } from "@/utils/common";
import uploadOSS from "@/components/uploadOSS.vue";
import coverImgTemplate from "@/components/coverImgTemplate.vue";
import html2canvas from "html2canvas";
import { Debounce } from "@/utils/debounce";
export default {
  components: {
    Plus,
    Delete,
    uploadOSS,
    coverImgTemplate,
    html2canvas,
  },
  data() {
    return {
      title: "", // 搜索的标题
      categoryList: [], // 分类列表
      category_id: 0, // 分类id
      time_range: [], // 发布时间范围
      listLoading: false,
      list: [],
      page: 1,
      pageSize: 15,
      total: 0,

      formShow: false,
      formTitle: "",
      formLoading: false,
      form: {
        id: null,
        title: "",
        sub_title: "",
        category_id: null,
        video: "", //
        image: "", // 封面图
        image_type: "9:16", // 选取的视频封面类型
        image_time: "", // 选取的视频封面时间
        is_recommend: 1, // 是否推荐
      }, // 提交表单信息
      editor: null,

      rateRadioList: [
        {
          label: "16:9",
        },
        {
          label: "9:16",
        },
      ],
    };
  },
  mounted() {
    let that = this;
    window.onmessageSocket = this.onmessage;
    this.getLiveVideoCategoryList();
    this.getLiveVideoList(1);
  },
  methods: {
    onmessage(e) {},
    // 获取当前视频画面
    getCurrentVideoImg() {
      const video = document.getElementById("video");
      console.log("video", video.currentTime);
      this.form.image_time = Math.floor(video.currentTime * 1000);
      this.changeImg();
    },
    changeImg(e) {
      console.log("image_time", this.form.image_time);
      const image_type = this.form.image_type;
      let width = image_type == "16:9" ? 800 : 450;
      let height = image_type == "16:9" ? 450 : 800;
      this.form.image =
        this.form.video +
        "?x-oss-process=video/snapshot,t_" +
        this.form.image_time +
        ",f_jpg,w_" +
        width +
        ",h_" +
        height +
        ",m_fast";
    },
    uploadSuccess(url) {
      this.form.video = url;
      const image_type = this.form.image_type;
      let width = image_type == "16:9" ? 800 : 450;
      let height = image_type == "16:9" ? 450 : 800;
      this.form.image =
        url +
        "?x-oss-process=video/snapshot,t_0,f_jpg,w_" +
        width +
        ",h_" +
        height +
        ",m_fast";
    },
    // 获取分类列表
    async getLiveVideoCategoryList() {
      const res = await Service.getLiveVideoCategoryList();
      this.categoryList = res.data;
    },
    // 获取直播视频列表
    async getLiveVideoList(page) {
      this.listLoading = true;
      const res = await Service.getLiveVideoList({
        page,
        category_id: this.category_id,
      });
      const list = res.data.data;
      for (const item of list) {
        item.createtime_text = formatTime(item.createtime, "Y-M-D h:m:s");
        item.updatetime_text = formatTime(item.updatetime, "Y-M-D h:m:s");
      }
      this.list = list;
      this.total = res.data.total;
      this.listLoading = false;
    },
    handleRemove() {
      console.log("@删除图片");
      this.form.image = "";
    },
    handleAvatarSuccess(res, file) {
      this.form.image = res.data.fullurl;
    },
    search() {
      this.list = [];
      this.page = 1;
      this.getLiveVideoList(1);
    },
    onSubmit: Debounce(async function () {
      var form = this.form;
      var obj = { ...form };
      if (!obj.title) {
        this.$message.error("请输入标题");
        return false;
      }
      if (!obj.category_id) {
        this.$message.error("请选择分类");
        return false;
      }
      if (!obj.video) {
        this.$message.error("请上传视频");
        return false;
      }

      this.formLoading = true;
      console.log("this.form", this.form);
      html2canvas(document.querySelector("#capture1"), {
        scale: 2,
        width: $("#capture1").width(), //设置canvas尺寸与所截图尺寸相同，防止白边
        height: $("#capture1").height(), //防止白边
      }).then((canvas) => {
        var imgUrl = canvas.toDataURL("image/png");
        console.log("imgUrl", imgUrl);
        setTimeout(() => {
          this.formLoading = false;
        }, 1500);
        this.submit1(imgUrl);
      });
    }),
    async submit1(imgUrl) {
      const res = await Service.uploadBase64({
        pic: imgUrl,
      });
      this.submit2(res.data.url);
    },
    async submit2(image) {
      var form = this.form;
      var obj = { ...form };
      obj.image = image;
      if (!obj.title) {
        this.$message.error("请输入标题A");
        return false;
      }
      if (!obj.category_id) {
        this.$message.error("请选择分类");
        return false;
      }
      if (!obj.video) {
        this.$message.error("请上传视频");
        return false;
      }

      var res = null;
      if (obj.id) {
        res = await Service.editLiveVideo(obj);
      } else {
        res = await Service.addLiveVideo(obj);
      }
      this.$message.success(res.msg);
      this.formShow = false;
      this.getLiveVideoList(this.page);
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    handleCurrentChange(val) {
      this.list = [];
      this.getLiveVideoList(val);
    },
    // 获取图片名称
    getFileName(file) {
      var d = new Date();
      var year = d.getFullYear();
      var month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      var date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      var type = file.type.substr(
        file.type.lastIndexOf("/") + 1,
        file.type.length
      );
      return (
        "push/toutiao/" +
        year +
        month +
        date +
        "/" +
        Math.round(new Date()) +
        "." +
        type
      );
    },
    // 信息详情
    async getInfoDetail(id) {
      const res = await Service.liveVideoInfo({
        id,
      });
      console.log("信息详情", res);
      var data = res.data;
      this.form = {
        id: data.id,
        title: data.title,
        sub_title: data.sub_title,
        category_id: data.category.id,
        video: data.video, //
        image: data.image, // 封面图
        image_type: data.image_type, // 选取的视频封面类型
        image_time: data.image_time, // 选取的视频封面时间
        is_recommend: data.is_recommend, // 是否推荐
      };
    },
    handleFormShow(e) {
      console.log("e", e);
      this.form = {
        id: null,
        title: "",
        sub_title: "",
        category_id: null,
        video: "", //
        image: "", // 封面图
        image_type: "16:9", // 选取的视频封面类型
        image_time: "", // 选取的视频封面时间
        is_recommend: 1, // 是否推荐
      }; // 提交表单信息
      if (e != undefined) {
        // 修改
        this.formTitle = "修改";
        this.getInfoDetail(e.id);
      } else {
        // 添加
        this.formTitle = "添加";
      }
      this.formShow = true;
    },
    handleDelete(index, row) {
      this.$confirm("确认删除该数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.handleDeleteApi(index, row);
      });
    },
    async handleDeleteApi(index, row) {
      const res = await Service.delLiveVideo({
        id: row.id,
      });
      this.$message.success(res.msg);
      this.getLiveVideoList(this.page);
    },
  },
};
</script>

<style scoped>
.body {
  height: 100vh;
}

.plate_title {
  background-color: #f8fcff;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  padding: 18px 0;
  border-top: 1px solid #ebeced;
  border-bottom: 1px solid #ebeced;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plate_title_name {
  position: relative;
  line-height: 16px;
  padding-left: 30px;
}

.plate_title_name::before {
  content: "";
  position: absolute;
  left: 13px;
  width: 4px;
  height: 16px;
  background-color: #5389f5;
  border-radius: 5px;
}

.head > div {
  margin: 0 30px 20px 0;
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.el-form {
  width: 90%;
}

.icon_del {
  display: block;
  width: 26px;
  height: 26px;
  margin-left: 10px;
}

.avatar-uploader_div {
  width: 148px;
  height: 148px;
  display: block;
  position: relative;
}

.avatar-uploader .avatar {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.zhezhao {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.zhezhao:hover {
  opacity: 1;
}

/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

/deep/.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  text-align: center;
}
</style>

import request from "../utils/request";
// 获取直播视频分类列表
export const getLiveVideoCategoryList = (query) => {
  return request({
    url: "/teacher/live_video/getCategoryList",
    method: "POST",
    data: query,
  });
};
// 添加直播视频分类
export const addLiveVideoCategory = (query) => {
  return request({
    url: "/teacher/live_video/addCategory",
    method: "POST",
    data: query,
  });
};
// 编辑直播视频分类
export const editLiveVideoCategory = (query) => {
  return request({
    url: "/teacher/live_video/editCategory",
    method: "POST",
    data: query,
  });
};
// 直播视频分类详情
export const liveVideoCategoryInfo = (query) => {
  return request({
    url: "/teacher/live_video/categoryInfo",
    method: "POST",
    data: query,
  });
};
// 删除直播视频分类
export const delLiveVideoCategory = (query) => {
  return request({
    url: "/teacher/live_video/delCategory",
    method: "POST",
    data: query,
  });
};
// 获取直播视频列表
export const getLiveVideoList = (query) => {
  return request({
    url: "/teacher/live_video/getLiveVideoList",
    method: "POST",
    data: query,
  });
};
// 添加直播视频
export const addLiveVideo = (query) => {
  return request({
    url: "/teacher/live_video/addLiveVideo",
    method: "POST",
    data: query,
  });
};
// 编辑直播视频
export const editLiveVideo = (query) => {
  return request({
    url: "/teacher/live_video/editLiveVideo",
    method: "POST",
    data: query,
  });
};
// 直播视频详情
export const liveVideoInfo = (query) => {
  return request({
    url: "/teacher/live_video/liveVideoInfo",
    method: "POST",
    data: query,
  });
};
// 删除直播视频
export const delLiveVideo = (query) => {
  return request({
    url: "/teacher/live_video/delLiveVideo",
    method: "POST",
    data: query,
  });
};
// 上传base64
export const uploadBase64 = (query) => {
  return request({
    url: "/teacher/common/uploadBase64",
    method: "POST",
    data: query,
  });
};

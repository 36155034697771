<template>
  <div>
    <el-select
      @change="handleChange"
      v-model="currentTemplateIndex"
      placeholder="请选择封面模板"
    >
      <el-option
        v-for="(item, index) in list"
        :key="index"
        :label="item.name"
        :value="index"
      >
      </el-option>
    </el-select>
    <div class="pr mt20 flex at_c jc_c" id="capture1">
      <img class="cover_img" :src="list[currentTemplateIndex].imgSrc" alt="" />
      <div class="content_text">
        <div class="title" :style="{ color: list[currentTemplateIndex].color }">
          {{ title_text }}
        </div>
        <template v-if="sub_title_text">
          <img
            class="line_img"
            :src="list[currentTemplateIndex].borderImg"
            alt=""
          />
          <div
            class="sub_title"
            :style="{ color: list[currentTemplateIndex].color }"
          >
            {{ sub_title_text }}
          </div>
          <img
            class="line_img"
            style="margin-top: 3px"
            :src="list[currentTemplateIndex].borderImg"
            alt=""
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    sub_title: {
      type: String,
      default: "",
    },
  },
  watch: {
    title(e) {
      this.title_text = e;
    },
    sub_title(e) {
      this.sub_title_text = e;
    },
  },
  data() {
    return {
      title_text: "",
      sub_title_text: "",
      currentTemplateType: 1,
      currentTemplateIndex: 0,
      list: [
        {
          name: "模版一",
          type: 1,
          imgSrc: require("@/assets/img/live/template_1.png"),
          color: "#0C45B8",
          borderColor: "rgb(12, 69, 184, 0.6)",
          borderImg: require("@/assets/img/live/template_line_1.png"),
        },
        {
          name: "模版二",
          type: 2,
          imgSrc: require("@/assets/img/live/template_2.png"),
          color: "#7D5019",
          borderColor: "rgb(125, 80, 25, 0.6)",
          borderImg: require("@/assets/img/live/template_line_2.png"),
        },
        {
          name: "模版三",
          type: 3,
          imgSrc: require("@/assets/img/live/template_3.png"),
          color: "#113151",
          borderColor: "rgb(17, 49, 81, 0.6)",
          borderImg: require("@/assets/img/live/template_line_3.png"),
        },
        {
          name: "模版四",
          type: 4,
          imgSrc: require("@/assets/img/live/template_4.png"),
          color: "#0D5552",
          borderColor: "rgb(13, 85, 82, 0.6)",
          borderImg: require("@/assets/img/live/template_line_4.png"),
        },
        {
          name: "模版五",
          type: 5,
          imgSrc: require("@/assets/img/live/template_5.png"),
          color: "#1B2A73",
          borderColor: "rgb(27, 42, 115, 0.6)",
          borderImg: require("@/assets/img/live/template_line_5.png"),
        },
      ],
    };
  },
  mounted() {
    this.title_text = this.title;
    this.sub_title_text = this.sub_title;
  },
  methods: {
    handleChange(index) {
      console.log("index", index);
      this.currentTemplateIndex = index;
      this.currentTemplateType = this.list[index].type;
    },
  },
};
</script>

<style scoped>
.cover_img {
  width: 230px;
  display: block;
}

.content_text {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 87px 20px 10px;
  box-sizing: border-box;
}

.title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 8px;
}

.sub_title {
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
}

.line_img {
  width: 100%;
  height: 1px;
  display: block;
}
</style>
